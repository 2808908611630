.DayPicker__withBorder {
}

.DayPickerKeyboardShortcuts_show {
    display: none !important;
}
.CalendarDay__selected_span {
    background: #86cba4;
    border: 1px double #81C39E;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #009842;
    border: 1px double #006F30;
}

.react-datepicker {
    // background-color: #00000000 !important;
    width: 100% !important;
    border: 0 !important;
    padding: 1rem !important;
    .react-datepicker__header {
        padding: 0 !important;
        border: 0 !important;
        background-color: #00000000 !important;
    }
    .react-datepicker__month-container {
        width: 100% !important;
    }
    .react-datepicker__today-button {
        border: 0 !important;
        background-color: #00000000 !important;
    }
    .react-datepicker__navigation {
        top: 1rem !important;
        .react-datepicker__navigation-icon::before {
            border-color: #009842 !important;
        }
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        background-color: #009842 !important;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: #009842 !important;
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 2rem !important;
        line-height: 2rem !important;
        font-size: 1rem !important;
    }
}
