// .nuvo-tailwind .extra-scope-dvicgs.extra-scope-dvicgs,
// .nuvo-tailwind .extra-scope-1do5o1f.extra-scope-1do5o1f {
//     opacity: 1 !important;
// }

.nuvo-tailwind .max-w-screen-3xl {
    max-width: 100% !important;
    border-radius: 0.3rem;
    overflow: hidden;
    margin: 9px 0px;
}
