.drawer {
  .ant-menu-inline,
  .ant-menu-vertical {
    border-right: none;
  }
  &-content {
    padding-top: 40px;
  }
  &-left {
    .ant-menu-inline .ant-menu-item:after,
    .ant-menu-vertical .ant-menu-item:after {
      left: 0;
      right: auto;
    }
  }
}

.drawer-wrapper {
  .drawer {
    animation: AlphaTo 0.3s ease-out 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
}

@keyframes AlphaTo {
  to {
    opacity: 1;
    left: 0;
  }
}

.parent-demo {
  position: relative;
  overflow: hidden;
  .drawer {
    position: absolute;
  }
}
