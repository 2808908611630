@page {
  size: A4;
  margin: 12mm 12mm 18mm 12mm !important;
}

@media print {
  html,
  body {
    width: 210mm !important;
    height: 297mm !important;
  }
  .page {
    margin: 0 !important;
    padding: 0 !important;
    border: initial !important;
    border-radius: initial !important;
    width: initial !important;
    min-height: initial !important;
    box-shadow: initial !important;
    background: initial !important;
    page-break-after: always !important;
    counter-reset: page 1;
    .pagenumber {
      &:after {
        counter-increment: page;
        content: 'Seite ' counter(page);
      }
    }
  }
}
