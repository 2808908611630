/* Begin Bootstrap */
$enable-important-utilities: false;

$theme-colors: (
  "primary": #009842,
  "secondary": #6c757d,
  "success": #009842,
  "info": #0d6efd,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #ffffff,
  "dark": #212529
);

$body-bg: #f2f2f2;
$--font-family-sans-serif:
  "Trebuchet MS",
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  "Noto Sans",
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";
$--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
// $spacer: 32px;
$grid-gutter-width: 72px;
// Navbar
$navbar-height: 84px;
$navbar-default-color: #86cba4;
$navbar-default-bg: #ffffff;
$navbar-default-border: #dbdbdb;
$navbar-brand-font-size: 1.58rem;
// Dropdown
$dropdown-border-radius: 0;
$dropdown-border-width: 0;

$--bs-danger: var(--bs-red);
$--bs-warning: var(--bs-yellow);
$--bs-red: var(--bs-yellow);

$btn-close-color: #ffffff;

$accordion-icon-active-color: #009842;
$accordion-button-active-color: #009842;
$accordion-bg: #ffffff;
$accordion-button-active-bg: #ffffff;

$dropdown-link-active-bg: transparent;
$dropdown-link-hover-bg: transparent;
/* End Bootstrap */
@import "~bootstrap/scss/bootstrap";

.btn-outline-dark:hover {
  background-color: #fff;
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  background-color: #009842;
  border-color: #009842;
  color: #fff !important;
  p {
    color: #fff !important;
  }
}

body {
  touch-action: pan-y;
}

@include media-breakpoint-down(lg) {
  .modal-footer {
    button {
      flex: 1;
      &.btn-light {
        border: 1px solid var(--bs-gray-400);
      }
    }
  }
}

.btn-primary {
  color: #fff !important;
}

.react-datepicker__portal {
  // background-color: rgba(0, 0, 0, 1) !important;
  z-index: 9999 !important;
}
