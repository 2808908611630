.tabs-mobile {
    margin: 1rem 0;
    position: relative;

    button {
        padding: 1rem;
        margin-top: 1rem;
        background: #efefef;
        color: #a1a1a1;
        opacity: 1;
        font-family: var(--bs-btn-font-family);
        font-size: var(--bs-btn-font-size);
        font-weight: var(--bs-btn-font-weight);
        line-height: var(--bs-btn-line-height);
        user-select: none;
        border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
        border-radius: var(--bs-btn-border-radius);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span.icon {
            align-self: flex-end;
            svg {
                width: 14px;
                height: 14px;
                display: inline-block;
                margin: 0 6px 4px 0;
                fill: #a1a1a1;
                color: #a1a1a1;
            }
        }
    }

    ul {
        background-color: #ffffff;
        z-index: 999999;
        border-radius: 0.375rem;
        border: 1px solid #e5e5e5;
        width: 100%;
        max-height: 15rem;
        overflow-y: scroll;
        overflow-x: hidden;
        position: absolute;
        padding: 4px 0;
        li {
            font-family: var(--bs-btn-font-family);
            font-size: var(--bs-btn-font-size);
            font-weight: var(--bs-btn-font-weight);
            line-height: var(--bs-btn-line-height);
            user-select: none;
            padding: 1rem;
        }
    }
}
